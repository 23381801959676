import {
    createCookie,
    eraseCookie,
    readCookie,
} from './../helpers/cookies-helper';
const switcherID = 'js-select-loacalization';
const switcher = document.getElementById(switcherID);

if (switcher) {
    switcher.addEventListener('change', e => {
        let localization = switcher.value;
        let cookie = readCookie('user_localization');
        if (cookie) {
            eraseCookie('eraseCookie');
            createCookie('user_localization', localization, 120);
        } else {
            createCookie('user_localization', localization, 120);
        }
        window.location.reload();
    });
}

const switcherLink = document.querySelectorAll('.js-select-link');
switcherLink.forEach(item => {
    item.addEventListener('click', e => {
        e.preventDefault();
        let localization = item.getAttribute('data-cookie');
        let href = item.getAttribute('href');
        let cookie = readCookie('user_localization');
        if (cookie) {
            eraseCookie('eraseCookie');
            createCookie('user_localization', localization, 120);
        } else {
            createCookie('user_localization', localization, 120);
        }
        if (href) {
            window.location.href = href;
        }
        else {
            window.location.reload();
        }
    });
});